import { isEmpty, isNull } from 'lodash';
import store from '../../store/store';
import { Permissions } from './access-control/enums/permissions';
import hasPermission from './access-control/has-permission';
import { SubscriptionPlans } from './subscription-plans';

const getState = () => store?.getState();
const defaultPlanCode = SubscriptionPlans.TRIAL;

export const getUserFirstName = () => getState().home?.firstName;

export const getUserLastName = () => getState().home?.lastName;

export const getAdminFirstName = () => getState().home?.adminDetails?.firstName;

export const getUserFullName = () =>
  `${getUserFirstName()} ${getUserLastName()}`;

export const getUserEmail = () => getState().home?.email;

export const getUserSignUpDate = () => getState().home?.createdAt;

export const getUserTrackingId = () => getState().home?.trackingId;

export const getUserRole = () => getState().home?.role;

export const isAgencyUser = () => getState().home?.isAgencyUser;

export const isAgency = () => !!parseInt(getState().home?.isAgency, 10);

export const getAgencyUserRole = () => isAgencyUser() && getState().home?.role;

export const getAgencyClient = () => getState().home?.client;

export const getAgencyName = () =>
  isAgencyUser() && getState().home?.agencyConfig?.agencyName;

export const getUserCurrentPlan = () =>
  getState().home.subscription?.planCode || defaultPlanCode;

export const getUserUseCase = () => getState().home?.useCase;

export const getUserIndustry = () => getState().home?.industry;

export const getUserCompanySize = () => getState().home?.companySize;

export const getUserJobRole = () => getState().home?.jobRole;

export const getUserPlanStartDate = () => getState().home.subscription?.startAt;

export const getUserPlanEndDate = () =>
  getState().home.subscription?.nextBillingAt;

export const getUserSubscribedAt = () => getState().home.subscription?.startAt;

export const getUserCountry = () => getState().home?.country;

export const getUserPhoneNumber = () => getState().home?.phoneNumber;

export const getUserReferralPage = () => 'www.saleshandy.com';

export const getUserShAccountId = () => getState().home?.shAccountId;

export const getUserPlanCode = () => getState().home?.subscription?.planCode;

export const getUserPlanName = () => getState().home?.subscription?.planName;

export const getUserTimeZone = () => getState().home?.timeZone;

export const getUserLeadFinderPlanName = () =>
  getState().home?.leadFinderSubscription?.planName;

export const isMultichannelActive = () =>
  getState()?.home?.isMultichannelActive;

export const userHasWhitelabelAccessPermission = () =>
  hasPermission(Permissions.WHITELABEL_ACCESS);

export const isWhiteLabel = () =>
  getState()?.home?.agencyConfig?.clientBaseUrl ||
  getState()?.home?.agencyConfig?.icon;

export const isWhitelabelWithoutClientView = () =>
  isNull(getAgencyClient()) || isEmpty(getAgencyClient()) || !isWhiteLabel();

export const isWhitelabelWithClientView = () =>
  (!isNull(getAgencyClient()) || !isEmpty(getAgencyClient())) && isWhiteLabel();

export const getAgencyConfigDetails = () => getState().home.agencyConfig;

export const getWhitelabelCompanyName = () =>
  getState()?.home?.agencyConfig?.companyName;
